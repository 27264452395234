import { makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { MOBILE_SHIFT } from "../pageStyle";

export const useRegisterStyle = makeStyles({
  page: {
    display: "grid",

    gridTemplateAreas: `"wizard"
                        "content"
                        "footer"`,
    gridTemplateRows: "52px 1fr 112px",
    [`@media (min-width: ${MOBILE_SHIFT})`]: {
      gridTemplateAreas: `"wizard content"
                        "wizard footer"`,
      gridTemplateColumns: "226px 1fr",
      gridTemplateRows: "1fr 112px",
    },
    height: "100vh",
  },
  content: {
    overflowY: "auto",
    paddingLeft: tokens.spacingHorizontalL,
    paddingBottom: tokens.spacingVerticalXL,
    [`@media (min-width: ${MOBILE_SHIFT})`]: {
      paddingLeft: tokens.spacingHorizontalXXXL,
      paddingBottom: tokens.spacingVerticalXXXL,
    },
    ...shorthands.gridArea("content"),
  },
});
