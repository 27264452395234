import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  Image,
  makeStyles,
  tokens,
  Text,
  Button,
  shorthands,
  mergeClasses,
  Tooltip,
  Spinner,
} from "@fluentui/react-components";
import { CGU } from "./rules/CGU";
import { LAYOUT_SHIFT, usePageStyle } from "./pageStyle";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "usehooks-ts";
import {
  ExpandUpRight24Regular,
  SportSoccer24Regular,
} from "@fluentui/react-icons";
import { useCurrentUser, useDesktopHeader } from "../store";
import { useEffect, useRef, useState } from "react";
import { Olympics } from "../utils";
import { useQuery } from "@tanstack/react-query";
import { getAdmins, getSettings } from "../api";

const useHeaderStyle = makeStyles({
  headerStyle: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    color: tokens.colorNeutralForegroundStaticInverted,
    ...shorthands.padding(tokens.spacingVerticalL, tokens.spacingHorizontalXXL),
  },
  image: {
    display: "none",
    [`@media (min-width: ${LAYOUT_SHIFT})`]: {
      display: "block",
      height: "152px",
      paddingRight: "50px",
      alignSelf: "center",
    },
  },
  mobileHeader: {
    display: "flex",
    ...shorthands.padding(
      0,
      tokens.spacingHorizontalM,
      0,
      tokens.spacingHorizontalM
    ),
    [`@media (min-width: ${LAYOUT_SHIFT})`]: {
      display: "none",
    },
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    columnGap: tokens.spacingHorizontalM,
    backgroundColor: "#4B53BC1A",
  },
  hidden: {
    display: "none",
  },
  settings: {
    position: "absolute",
    right: tokens.spacingHorizontalS,
    top: tokens.spacingVerticalS,
    [`@media (min-width: ${LAYOUT_SHIFT})`]: {
      right: tokens.spacingHorizontalL,
      top: tokens.spacingVerticalM,
    },
  },
});

export function Header() {
  const { header, headerFull, layout, layoutHeaderFull, layoutHeaderReduced } =
    usePageStyle();
  const { headerStyle, image, mobileHeader, hidden, settings } =
    useHeaderStyle();
  const { t } = useTranslation();
  const desktop = useMediaQuery(`(min-width: ${LAYOUT_SHIFT})`);
  const { header: desktopHeader, update } = useDesktopHeader();
  const ref = useRef<HTMLDivElement>(null);
  const { data: headerSettings, isLoading } = useQuery({
    queryKey: ["HEADER"],
    queryFn: getSettings,
  });
  const navigate = useNavigate();
  const { company, id } = useCurrentUser();
  const [showSettings, setShowSettings] = useState(false);
  const location = useLocation();

  const { data } = useQuery({
    queryKey: ["REGISTER", "ADMIN", company.id],
    queryFn: async () => {
      const admins = await getAdmins({ companyId: company.id });
      return admins;
    },
  });

  useEffect(() => {
    if (data && id && data.some((admin) => admin.user_id === id)) {
      setShowSettings(true);
    } else {
      setShowSettings(false);
    }
  }, [data, id]);

  return (
    <div
      className={mergeClasses(
        layout,
        desktopHeader && layoutHeaderFull,
        desktopHeader === false && layoutHeaderReduced
      )}
    >
      <div
        className={mergeClasses(header, desktopHeader && headerFull)}
        ref={ref}
        style={{
          backgroundImage:
            desktop && desktopHeader ? `url(${headerSettings?.header})` : "",
        }}
      >
        <CGU />
        {!headerSettings && isLoading && <Spinner size="large" />}
        {headerSettings && (
          <>
            {desktop && (
              <div
                className={mergeClasses(
                  headerStyle,
                  desktopHeader === false && hidden
                )}
              >
                <Text
                  size={600}
                  weight="bold"
                  style={{
                    color: tokens.colorNeutralForegroundStaticInverted,
                  }}
                >
                  {headerSettings.title}
                </Text>
                <Text
                  style={{
                    opacity: 0.6,
                    padding: `${tokens.spacingVerticalMNudge} 0`,
                  }}
                  size={400}
                  weight="bold"
                >
                  {headerSettings.date}
                </Text>
                <Text style={{ flexGrow: 1, opacity: 0.6 }}>
                  {headerSettings.subtitle}
                </Text>
                <Button style={{ alignSelf: "flex-start" }} onClick={update}>
                  {t(Olympics ? "header.JO.moreInfo" : "header.moreInfo")}
                </Button>
              </div>
            )}
            {desktop && desktopHeader === false && (
              <div
                style={{
                  display: "flex",
                  padding: `0 ${tokens.spacingHorizontalM} 0 ${tokens.spacingHorizontalM}`,
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  columnGap: tokens.spacingHorizontalM,
                  backgroundColor: "#4B53BC1A",
                  height: "52px",
                }}
              >
                <Tooltip
                  relationship="label"
                  content={t("header.showHeader")}
                  mountNode={ref.current}
                >
                  <Button
                    onClick={update}
                    style={{
                      height: "30px",
                      minWidth: 0,
                      width: "30px",
                      position: "absolute",
                      left: "0px",
                      padding: "0px",
                    }}
                  >
                    <ExpandUpRight24Regular
                      style={{ width: "20px", height: "20px" }}
                    />
                  </Button>
                </Tooltip>
                <SportSoccer24Regular
                  style={{ color: "rgba(75, 83, 188, 1)" }}
                />
                <Text weight="semibold" style={{ color: "#4B53BC" }}>
                  Euro 2024
                </Text>
                <SportSoccer24Regular
                  style={{ color: "rgba(75, 83, 188, 1)" }}
                />
              </div>
            )}
            <div className={mobileHeader}>
              <SportSoccer24Regular style={{ color: "rgba(75, 83, 188, 1)" }} />
              <Text weight="semibold" style={{ color: "#4B53BC" }}>
                Euro 2024
              </Text>
              <SportSoccer24Regular style={{ color: "rgba(75, 83, 188, 1)" }} />
            </div>
            {desktopHeader && headerSettings.logo && (
              <>
                <Image
                  src={headerSettings.logo}
                  alt="Euro 2024"
                  className={image}
                />
              </>
            )}
            {showSettings && (
              <Button
                className={settings}
                onClick={() => navigate("/register")}
              >
                {t("settings")}
              </Button>
            )}
          </>
        )}
      </div>
      <Outlet />
    </div>
  );
}
