import {
  tokens,
  Text,
  makeStyles,
  shorthands,
} from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "usehooks-ts";
import { MatchPage } from "../../../../type";
import { LAYOUT_SHIFT } from "../../../pageStyle";
import { TeamHeader } from "./TeamHeader";
import { StatType } from "@winnerscore/common";

const useContentStyle = makeStyles({
  container: {
    ...shorthands.gridArea("aside"),
    maxWidth: "776px",
    [`@media (min-width: ${LAYOUT_SHIFT})`]: {
      ...shorthands.gridArea("content-left"),
      maxWidth: "388px",
      overflowY: undefined,
      paddingTop: tokens.spacingVerticalL,
      alignSelf: "start",
      paddingRight: tokens.spacingHorizontalL,
    },
    display: "flex",
    justifySelf: "center",
    width: "100%",
    flexDirection: "column",
    rowGap: tokens.spacingVerticalS,
    alignSelf: "center",
  },
});

function StatDetailRow({ stat }: { stat: StatType }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: `${tokens.spacingVerticalNone} ${tokens.spacingHorizontalL}`,
      }}
    >
      <Text
        style={{
          width: "30px",
          flexShrink: 0,
          color: tokens.colorNeutralForeground3BrandHover,
        }}
        weight="semibold"
      >
        {stat.valueTeamA}
      </Text>
      <Text>{stat.name}</Text>
      <Text
        style={{ width: "30px", flexShrink: 0 }}
        align="end"
        weight="semibold"
      >
        {stat.valueTeamB}
      </Text>
    </div>
  );
}

export function StatDetail({ match }: { match: MatchPage }) {
  const { t } = useTranslation();
  const desktop = useMediaQuery(`(min-width: ${LAYOUT_SHIFT})`);
  const { container } = useContentStyle();
  return (
    <>
      <Text
        style={{
          gridArea: desktop ? "title-left" : "aside",
          display: desktop ? "inherit" : "none",
          paddingTop: tokens.spacingVerticalM,
        }}
        align="center"
        weight="bold"
        size={400}
      >
        {t("matchs.statDetail")}
      </Text>

      <div className={container}>
        <div
          style={{
            gridColumnStart: "1",
            gridColumnEnd: "3",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            paddingBottom: "16px",
          }}
        >
          <TeamHeader
            imageUrl={match.team_a.image ?? ""}
            teamName={match.team_a.team_name}
          />
          <TeamHeader
            imageUrl={match.team_b.image ?? ""}
            teamName={match.team_b.team_name}
            side="right"
          />
        </div>
        {match.stats.details.map((stat) => (
          <StatDetailRow key={stat.name} stat={stat} />
        ))}
      </div>
    </>
  );
}
