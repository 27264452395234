import {
  Divider,
  makeStyles,
  shorthands,
  Text,
  tokens,
} from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import { MOBILE_SHIFT } from "../pageStyle";
import { CheckmarkRegular } from "@fluentui/react-icons";

const useWizardStyle = makeStyles({
  wizard: {
    display: "grid",
    ...shorthands.gridArea("wizard"),
  },
  divider: {
    display: "none",
    [`@media (min-width: ${MOBILE_SHIFT})`]: {
      height: "100%",
      justifySelf: "end",
      width: "2px",
      flexGrow: 0,
      display: "flex",
    },
  },
  wizardElements: {
    flexGrow: 1,
    display: "flex",
    [`@media (min-width: ${MOBILE_SHIFT})`]: {
      flexDirection: "column",
      justifyItems: "center",
      paddingTop: tokens.spacingVerticalXXXL,
      rowGap: tokens.spacingVerticalL,
    },
    [`@media (max-width: ${MOBILE_SHIFT})`]: {
      justifyContent: "space-between",
      paddingTop: tokens.spacingVerticalM,
      paddingRight: tokens.spacingHorizontalXXXL,
    },
    paddingLeft: tokens.spacingHorizontalXXXL,
  },
});

export const Wizard = ({ step }: { step: number }) => {
  const { wizard, divider, wizardElements } = useWizardStyle();
  const { t } = useTranslation();
  const next = tokens.colorNeutralForegroundDisabled;
  return (
    <div className={wizard}>
      <div style={{ display: "flex" }}>
        <div className={wizardElements}>
          <Text
            weight={step === 0 ? "semibold" : "regular"}
            style={{
              color: 0 > step ? next : "",
              display: "flex",
              columnGap: tokens.spacingHorizontalS,
              alignItems: "center",
            }}
          >
            <CheckmarkRegular
              style={{
                color: tokens.colorPaletteLightGreenBackground3,
                opacity: step === 0 ? 0 : 1,
              }}
            />
            {t("faq.rules")}
          </Text>
          <Text
            weight={step === 1 ? "semibold" : "regular"}
            style={{
              color: 1 > step ? next : "",
              display: "flex",
              columnGap: tokens.spacingHorizontalS,
              alignItems: "center",
            }}
          >
            <CheckmarkRegular
              style={{
                color: tokens.colorPaletteLightGreenBackground3,
                opacity: step <= 1 ? 0 : 1,
              }}
            />
            {t("giftTitle")}
          </Text>
          <Text
            weight={step === 2 ? "semibold" : "regular"}
            style={{
              color: 2 > step ? next : "",
              display: "flex",
              columnGap: tokens.spacingHorizontalS,
              alignItems: "center",
            }}
          >
            <CheckmarkRegular
              style={{
                color: tokens.colorPaletteLightGreenBackground3,
                opacity: 0,
              }}
            />
            {t("admin")}
          </Text>
        </div>
        <Divider vertical className={divider} />
      </div>
    </div>
  );
};
