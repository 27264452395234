import { Image, Text, tokens } from "@fluentui/react-components";

export const CountryFlag = ({ flag, name }: { flag: string; name: string }) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      columnGap: tokens.spacingHorizontalM,
    }}
  >
    <Image
      src={flag}
      fit="contain"
      shape="circular"
      style={{ width: 30, height: 30 }}
    />
    <Text>{name}</Text>
  </div>
);
