import { tokens } from "@fluentui/react-components";
import { MatchStatus } from "../../../type";

export const getPointsColor = ({
  status,
  points,
  pointsMax,
}: {
  status: MatchStatus;
  points: number;
  pointsMax: number;
}) => {
  let color = tokens.colorNeutralForeground1;
  if (points === pointsMax) {
    color = tokens.colorPaletteLightGreenForeground3;
  } else if (points === 0 && status === MatchStatus.Ended) {
    color = tokens.colorPaletteRedBackground3;
  } else if (status === MatchStatus.Ended) {
    color = "rgba(255, 165, 56, 1)";
  }
  return color;
};
