import { useEffect, useState } from "react";

const getGoalDisplay = (
  elapsed: number,
  penalty = false,
  own_goal = false
): string | undefined => {
  if (penalty) {
    return `${elapsed}' (P)`;
  } else {
    return `${elapsed}'${own_goal ? " (OG)" : ""}`;
  }
};

export const useScorer = (
  scorer: {
    elapsed: number;
    penalty: boolean;
    name: string;
    own_goal: boolean;
  }[]
) => {
  const [penalties, setPenalties] = useState(0);
  const [normalGoals, setNormalGoals] = useState<string[]>([]);

  useEffect(() => {
    let peno = 0;
    const goals: Record<string, { displays: string[]; lastTime: number }> = {};
    for (const { elapsed, penalty, name, own_goal } of scorer) {
      if (elapsed >= 120 && penalty) {
        peno++;
        continue;
      }
      const display = getGoalDisplay(elapsed, penalty, own_goal);
      if (goals[name] && display) {
        goals[name].displays.push(display);
        if (goals[name].lastTime < elapsed) {
          goals[name].lastTime = elapsed;
        }
      } else if (display) {
        goals[name] = {
          displays: [display],
          lastTime: elapsed,
        };
      }
    }

    setNormalGoals(
      Object.entries(goals)
        .sort((a, b) => a[1].lastTime - b[1].lastTime)
        .map(([name, goals]) => `${name} ${goals.displays.join(", ")}`)
    );
    setPenalties(peno);
  }, [scorer]);

  return {
    penalties,
    normalGoals,
  };
};
